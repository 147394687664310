import React, { useState, useRef } from 'react';
import { Canvas, useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from '@react-three/drei';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import Navbar from './Navbar'; // Import the Navbar component
import ContactForm from './ContactForm';

const modelPaths = [
  { path: '/3dModels/babybluetesla2.glb', title: 'Baby Blue Tesla', description: 'A beautiful baby blue Tesla model.' },
  { path: '/3dModels/orangetesla.glb', title: 'Orange Tesla', description: 'A striking orange Tesla model.' },
  { path: '/3dModels/diner.glb', title: 'Diner', description: 'A detailed model of a classic diner.' },
];

interface ModelProps {
  modelPath: string;
  position: [number, number, number];
}

const Model: React.FC<ModelProps> = ({ modelPath, position }) => {
  const gltf = useLoader(GLTFLoader, modelPath);
  return <primitive object={gltf.scene} scale={[2, 2, 2]} position={position} />;
};

const ModelViewer: React.FC = () => {
  const [expandedModels, setExpandedModels] = useState<{ [key: string]: boolean }>({});
  const showcaseRef = useRef<HTMLDivElement>(null);

  const toggleModel = (title: string) => {
    setExpandedModels((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };

  return (
    <div>
      <Navbar />
      <div className="relative" ref={showcaseRef}>
        <div className="relative z-10 text-center">
          <h2 className="text-3xl font-bold mb-4">INTERACTIVE</h2>
        </div>
        <div className="relative z-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
          {modelPaths.map((model, index) => (
            <div key={index} className="relative w-full bg-white shadow-lg rounded-lg flex flex-col">
              <div
                className="flex justify-between items-center p-4 cursor-pointer lg:cursor-default"
                onClick={() => toggleModel(model.title)}
              >
                <h3 className="text-xl font-bold">{model.title}</h3>
                <span className="lg:hidden">
                  {expandedModels[model.title] ? <MdExpandLess /> : <MdExpandMore />}
                </span>
              </div>
              <div
                className={`${
                  expandedModels[model.title] ? 'max-h-96' : 'max-h-0'
                } lg:max-h-96 overflow-hidden transition-max-height duration-300 ease-in-out`}
              >
                <Canvas className="w-full h-72">
                  <ambientLight intensity={0.7} />
                  <pointLight position={[10, 10, 10]} intensity={0.5} />
                  <spotLight position={[5, 5, 5]} intensity={0.8} angle={0.2} penumbra={1} />
                  <directionalLight position={[2, 2, 5]} intensity={1} />
                  <Model modelPath={model.path} position={[-1, 0, -5]} />
                  <OrbitControls />
                </Canvas>
                <div className="p-2 bg-white bg-opacity-90 rounded-b-lg h-auto overflow-hidden">
                  <p className="text-sm">{model.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ContactForm />
    </div>
  );
};

export default ModelViewer;
