import React, { useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';

const skills = [
  { id: 'web_design', label: 'Web Design' },
  { id: 'marketing', label: 'Marketing' },
  { id: 'graphic_design', label: 'Graphic Design' },
  { id: '3d_animation', label: '3D Animation' },
  { id: 'blueprinting', label: 'Blueprinting' },
  { id: 'other', label: 'Other' },
];

const ContactForm: React.FC = () => {
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
  const [state, handleSubmit] = useForm("YOUR_FORMSPREE_ID"); // Replace YOUR_FORMSPREE_ID with your actual Formspree ID

  const handleSkillChange = (skillId: string) => {
    setSelectedSkills((prevSkills) =>
      prevSkills.includes(skillId)
        ? prevSkills.filter((id) => id !== skillId)
        : [...prevSkills, skillId]
    );
  };

  return (
    <div id="contactme" className="max-w-4xl mx-auto p-4 bg-gray-100 rounded-lg shadow-lg flex flex-wrap">
      <div className="w-full md:w-1/2 p-4">
        <h2 className="text-2xl font-bold mb-4 text-center">Contact Me</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              required
            />
          </div>

          <div>
            <label htmlFor="company" className="block text-sm font-medium text-gray-700">
              Company Name (optional)
            </label>
            <input
              type="text"
              id="company"
              name="company"
              className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>

          <div>
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
              Phone Number
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              required
            />
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              required
            />
            <ValidationError prefix="Email" field="email" errors={state.errors} />
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
              Description of Project
            </label>
            <textarea
              id="description"
              name="description"
              className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              rows={4}
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Skills
            </label>
            <div className="flex flex-wrap">
              {skills.map((skill) => (
                <label key={skill.id} className="mr-4 mt-2 flex items-center">
                  <input
                    type="checkbox"
                    name="skills"
                    value={skill.label}
                    onChange={() => handleSkillChange(skill.id)}
                    className="mr-2"
                  />
                  {skill.label}
                </label>
              ))}
            </div>
          </div>

          <button
            type="submit"
            disabled={state.submitting}
            className="mt-4 w-full p-2 bg-blue-500 text-white rounded-md shadow-sm hover:bg-blue-700 transition-colors duration-200"
          >
            Submit
          </button>
          <ValidationError errors={state.errors} />
          {state.succeeded && (
            <p className="mt-4 text-center text-green-500">Thank you! Your message has been sent.</p>
          )}
        </form>
      </div>
      <div className="w-full md:w-1/2 p-4 flex flex-col justify-center items-center bg-white rounded-lg shadow-md">
        <h3 className="text-xl font-bold mb-2">Al Gore</h3>
        <p className="mb-2">info@hirealgore.com</p>
        <p className="mb-2">(631) 306-4602</p>
      </div>
    </div>
  );
};

export default ContactForm;
