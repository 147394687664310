// src/App.tsx
import React from 'react';
import ModelViewer from './components/ModelViewer';
import ContactForm from './components/ContactForm';

const App: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <ModelViewer />
    </div>
  );
};

export default App;
